import { InfiniteData } from '@tanstack/react-query';

export const adaptTopicsBackToFront = (
  backendTopics: InfiniteData<BackendTopicResponse, number>
): { total: number; topics: Topic[] } => ({
  total: backendTopics.pages[0].total,
  topics: backendTopics.pages.flatMap(({ items: topics }) =>
    topics.map((topic) => ({
      id: topic.id,
      category: topic.esg_category,
      topic: topic.topic,
      subtopic: topic.name,
      material: topic.material,
      measured: topic.being_measured,
      impactScore: topic.impact_score,
      financialScore: topic.financial_score,
      sustainabilityMatterId: topic.csrd_sustainability_matter_id
    }))
  )
});
