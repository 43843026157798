import Button from 'components/ui/button/Button';
import CardWrapper from 'components/ui/cards/common/CardWrapper';
import { useTranslation } from 'react-i18next';
import { DMIROsAddUpdateModal } from './DMIROsAddUpdateModal';
import { useState } from 'react';

export const DMIROsBanner = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'doubleMateriality.iros.banner'
  });
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <>
      <CardWrapper className='flex flex-col items-center'>
        <span className='font-14 weight-600 my-0 mb-2' style={{ width: 'fit-content' }}>
          {t('title')}
        </span>

        <span className='font-12 mb-4'>{t('description')}</span>

        <Button
          text={t('button')}
          lookAndFeel='primary'
          size='small'
          className='max-w-fit'
          onClick={() => setShowAddModal(true)}
        />
      </CardWrapper>

      <DMIROsAddUpdateModal show={showAddModal} onClose={() => setShowAddModal(false)} />
    </>
  );
};
