import { InfiniteData } from '@tanstack/react-query';

export const adaptIROsBackToFront = (
  backendIROs: InfiniteData<BackendDMIROResponse, number>
): { total: number; iros: DMIRO[] } => ({
  total: backendIROs.pages[0].total,
  iros: backendIROs.pages.flatMap(({ items: iros }) =>
    iros.map((iro: BackendDMIRO) => ({
      id: iro.id,
      description: iro.description,
      type: iro.type.toLowerCase() as DMIRO['type'],
      score: iro.score,
      sustainabilityMatterId: iro.sustainability_matter_id
    }))
  )
});

export const adaptIROFrontToBack = (iro: DMIRO): BackendDMIRO => ({
  id: iro.id,
  sustainability_matter_id: iro.sustainabilityMatterId,
  description: iro.description,
  score: iro.score,
  type: iro.type.toLowerCase() as DMIRO['type']
});
