import { QueryFunction } from '@tanstack/react-query';
import apiFetch from 'utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';

type FetchSurveyResultsQueryKey = ['surveyResults', number | undefined, string];

export const surveyResultsService = {
  getAllSurveyResults: (async ({ queryKey, pageParam = 1 }) => {
    const [_, year, lang] = queryKey;
    const baseDataURL = '/sot_organization_csrd_survey_results';

    const { data } = await apiFetch<BackendSurveyResultsResponse>(
      'GET',
      getUrl(baseDataURL, {
        queryParams: generateQueryParamsFromObject({
          year,
          page: pageParam,
          lang
        })
      })
    );

    return data;
  }) as QueryFunction<BackendSurveyResultsResponse, FetchSurveyResultsQueryKey, number>
};
