import Button from 'components/ui/button/Button';
import Modal from 'components/ui/modal/Modal';
import { adaptFormValuesToIROData, useDMIROsForm } from '../../hooks/useDMIROsForm';
import { DMIROsForm } from './DMIROsForm';
import { useTranslation } from 'react-i18next';
import { useCreateOrUpdateIro } from '../../queries/useIROsQuery';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useContext } from 'react';

type Props = {
  show: boolean;
  onClose: () => void;
  iro?: DMIRO;
};

export const DMIROsAddUpdateModal: React.FC<Props> = ({ show, onClose, iro }) => {
  const { t } = useTranslation('', {
    keyPrefix: `doubleMateriality.iros.${iro?.id ? 'editModal' : 'addModal'}`
  });

  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  const { createOrUpdateIro, isPending } = useCreateOrUpdateIro({ year });

  const formHook = useDMIROsForm({
    iroData: iro,
    onSubmit: async (values) => {
      createOrUpdateIro(adaptFormValuesToIROData({ values, id: iro?.id }));
      onClose();
    }
  });

  return (
    <Modal.WithPortal show={show} onClose={onClose}>
      <Modal.Header title={t('title')} />
      <Modal.Content>
        <DMIROsForm
          formData={formHook.formData}
          setFormData={formHook.setFormData}
          selectableTopics={formHook.selectableTopics}
          selectableSubtopics={formHook.selectableSubtopics}
          iroTypes={formHook.iroTypes}
          errors={formHook.formData.errors}
          disabled={isPending}
        />
      </Modal.Content>
      <Modal.Buttons>
        <Button lookAndFeel='secondary' text={t('cancel')} size='medium' onClick={onClose} />
        <Button
          lookAndFeel='primary'
          text={t('save')}
          size='medium'
          onClick={formHook.handleSubmit}
          disabled={formHook.formData.errors.length > 0 || isPending}
          loading={isPending}
        />
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};
