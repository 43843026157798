import { InfiniteData } from '@tanstack/react-query';

export const adaptSurveyResultsBackToFront = (
  backendSurveyResults: InfiniteData<BackendSurveyResultsResponse, number>
): { total: number; surveyResults: SurveyResult[] } => {
  return {
    total: backendSurveyResults.pages[0].total,
    surveyResults: backendSurveyResults.pages.flatMap((page) =>
      page.items.map((backendSurveyResult) => adaptSurveyResultBackToFront(backendSurveyResult))
    )
  };
};

export const adaptSurveyResultBackToFront = (
  backendSurveyResult: BackendSurveyResult
): SurveyResult => {
  return {
    id: backendSurveyResult.id,
    topic: backendSurveyResult.topic,
    financialScore: backendSurveyResult.finance_score,
    impactScore: backendSurveyResult.impact_score,
    organizationId: backendSurveyResult.organization_id,
    organizationMeasurementCampaignId: backendSurveyResult.organization_measurement_campaign_id,
    sustainabilityMatterId: backendSurveyResult.csrd_sustainability_matter_id,
    esgCategory: backendSurveyResult.esg_category,
    subtopic: backendSurveyResult.name
  };
};
