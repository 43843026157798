import apiFetch from 'utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';
import { uploadFilePresignedUrl } from './aws';
import { QueryFunction } from '@tanstack/react-query';
/* GHG Output Data */
export type GHGOutputData = {
  id: string;
  name_dcycle: string;
  sources: string[];
  mandatory: boolean;
  being_reported: boolean;
  name_es: string;
  name_en: string;
  name_pt: string;
};
export type fetchGHGOutputDataProps = {
  sector: string;
  year: string;
  scope?: string;
};

export const fetchGHGOutputData = async (
  props: fetchGHGOutputDataProps
): Promise<GHGOutputData[]> => {
  try {
    const { sector, year, scope } = props;
    const url = `/sot_organization_ghg_output_data?sector=${encodeURIComponent(
      sector
    )}&year=${encodeURIComponent(year)}${scope ? `&scope=${encodeURIComponent(scope)}` : ''}`;
    const response = await apiFetch('GET', url);
    if (!response || !response.data) {
      throw new Error('Invalid response structure');
    }
    return response.data as GHGOutputData[];
  } catch (error) {
    console.error('Error fetching GHG output data:', error);
    throw new Error('Failed to fetch GHG output data. Please try again later.');
  }
};

/* CSRD Output Data */
export type CSRDOutputData = {
  id: string;
  name_dcycle: string;
  datapoint_id: string;
  mandatory: boolean;
  being_reported: boolean;
  status: 'COMPLETE' | 'WITH_DATA' | 'WITHOUT_DATA';
  with_files: boolean;
  with_data: boolean;
  link_efrag: string;
  input_data_description?: string;
  name_es: string;
  name_en: string;
  name_pt: string;
  default_response: string;
  unit_id: string;
};

export type fetchCSRDOutputDataProps = {
  sector: string;
  year: string;
  esgCategory?: string;
  esgSubcategory?: string;
  mandatory?: boolean;
  beingReported?: boolean;
  lang?: string;
};

export const fetchCSRDOutputData = async (
  props: fetchCSRDOutputDataProps
): Promise<CSRDOutputData[]> => {
  try {
    const { sector, year, lang, esgCategory, esgSubcategory, mandatory, beingReported } = props;

    const queryParams: Record<string, string> = {
      sector,
      year: String(year),
      lang: lang || 'es',
      ...(esgCategory && { esg_category: esgCategory }),
      ...(esgSubcategory && { esg_subcategory: esgSubcategory }),
      ...(mandatory === true || mandatory === false ? { mandatory: String(mandatory) } : {}),
      ...(beingReported === true || beingReported === false
        ? { beign_reported: String(beingReported) }
        : {})
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    const url = `/sot_organization_csrd_output_data?${queryString}`;

    const response = await apiFetch('GET', url);

    if (!response || !response.data) {
      throw new Error('Invalid response structure');
    }

    return response.data as CSRDOutputData[];
  } catch (error) {
    console.error('Error fetching CSRD output data:', error);
    throw new Error('Failed to fetch CSRD output data. Please try again later.');
  }
};

/* Double Materiality */
type FetchTopicsDataQueryKey = ['topicsData', string, string];

export const fetchTopicsData: QueryFunction<
  BackendTopicResponse,
  FetchTopicsDataQueryKey,
  number
> = async ({ queryKey, pageParam = 1 }) => {
  const [_, year, language] = queryKey;
  const baseDataURL = '/sot_organization_csrd_dm';

  const { data } = await apiFetch<BackendTopicResponse>(
    'GET',
    getUrl(baseDataURL, {
      queryParams: generateQueryParamsFromObject({
        year,
        page: pageParam,
        lang: language
      })
    })
  );

  return data;
};

type ModifyTopicDataProps = {
  id: string;
  changes: Partial<BackendTopic>;
  year: string;
};

export const modifyTopicData = async ({ id, changes, year }: ModifyTopicDataProps) => {
  const baseDataURL = `/sot_organization_csrd_dm/${id}`;
  apiFetch(
    'PATCH',
    getUrl(baseDataURL, {
      queryParams: generateQueryParamsFromObject({
        year
      })
    }),
    changes
  );
};

type FetchDMSuppliersQueryKey = ['providers', string, string | undefined];

export const fetchDMSuppliers: QueryFunction<
  BackendDMSupplierResponse,
  FetchDMSuppliersQueryKey,
  number
> = async ({ queryKey, pageParam = 1 }) => {
  const [, year, company_name] = queryKey;
  const baseDataURL = '/v1/sot_purchase_suppliers_by_quantity';

  const { data } = await apiFetch<BackendDMSupplierResponse>(
    'GET',
    getUrl(baseDataURL, {
      queryParams: generateQueryParamsFromObject({
        year,
        page: pageParam,
        business_name: company_name
      })
    })
  );

  return data;
};

type FetchDMClientsQueryKey = ['clients', string, string | undefined];

export const fetchDMClients: QueryFunction<
  BackendDMClientsResponse,
  FetchDMClientsQueryKey,
  number
> = async ({ queryKey, pageParam = 1 }) => {
  const [, year, company_name] = queryKey;
  const baseDataURL = '/v1/sot_stakeholder_clients';

  const { data } = await apiFetch<BackendDMClientsResponse>(
    'GET',
    getUrl(baseDataURL, {
      queryParams: generateQueryParamsFromObject({
        year,
        page: pageParam,
        business_name: company_name
      })
    })
  );

  return data;
};

export const getPresignedUrlClients = async (file_name: string, year: string) => {
  try {
    const response = await apiFetch(
      'POST',
      '/v1/sot_stakeholder_clients/bulk/csv',
      {
        file_name
      },
      {
        'x-year': year
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

type FetchDMInvestorsQueryKey = ['investors', string];

export const fetchDMInvestors: QueryFunction<
  BackendDMInvestorsResponse,
  FetchDMInvestorsQueryKey,
  number
> = async ({ queryKey, pageParam = 1 }) => {
  const [, year] = queryKey;
  const baseDataURL = '/v1/sot_stakeholder_investors';

  const { data } = await apiFetch<BackendDMInvestorsResponse>(
    'GET',
    getUrl(baseDataURL, {
      queryParams: generateQueryParamsFromObject({
        year,
        page: pageParam
      })
    })
  );

  return data;
};

export const createInvestor = async (data: BackendDMInvestor, year: string) => {
  const response = await apiFetch('POST', '/v1/sot_stakeholder_investors', data, {
    'x-year': year
  });
  return response.data;
};

export const updateInvestor = async (
  id: string,
  data: Partial<BackendDMInvestor>,
  year: string
) => {
  const response = await apiFetch('PATCH', `/v1/sot_stakeholder_investors/${id}`, data, {
    'x-year': year
  });
  return response.data;
};

export const deleteInvestor = async (id: string) => {
  const response = await apiFetch('DELETE', `/v1/sot_stakeholder_investors/${id}`);
  return response.data;
};

type FetchDMFinalUsersQueryKey = ['finalUsers', string];

export const fetchDMFinalUsers: QueryFunction<
  BackendDMFinalUsersResponse,
  FetchDMFinalUsersQueryKey,
  number
> = async ({ queryKey, pageParam = 1 }) => {
  const [, year] = queryKey;
  const baseDataURL = '/v1/sot_stakeholder_final_users';

  const { data } = await apiFetch<BackendDMFinalUsersResponse>(
    'GET',
    getUrl(baseDataURL, {
      queryParams: generateQueryParamsFromObject({
        year,
        page: pageParam
      })
    })
  );

  return data;
};

export const createFinalUser = async (data: BackendDMFinalUser, year: string) => {
  const response = await apiFetch('POST', '/v1/sot_stakeholder_final_users', data, {
    'x-year': year
  });
  return response.data;
};

export const updateFinalUser = async (
  id: string,
  data: Partial<BackendDMFinalUser>,
  year: string
) => {
  const response = await apiFetch('PATCH', `/v1/sot_stakeholder_final_users/${id}`, data, {
    'x-year': year
  });
  return response.data;
};

export const deleteFinalUser = async (id: string) => {
  const response = await apiFetch('DELETE', `/v1/sot_stakeholder_final_users/${id}`);
  return response.data;
};

type FetchDMOwnWorkersQueryKey = ['ownWorkers', string];

export const fetchDMOwnWorkers: QueryFunction<
  BackendDMOwnWorkersResponse,
  FetchDMOwnWorkersQueryKey,
  number
> = async ({ queryKey, pageParam = 1 }) => {
  const [, year] = queryKey;
  const baseDataURL = '/v1/sot_stakeholder_own_workers';

  const { data } = await apiFetch<BackendDMOwnWorkersResponse>(
    'GET',
    getUrl(baseDataURL, {
      queryParams: generateQueryParamsFromObject({
        year,
        page: pageParam
      })
    })
  );

  return data;
};

export const createOwnWorker = async (data: BackendDMOwnWorker, year: string) => {
  const response = await apiFetch('POST', '/v1/sot_stakeholder_own_workers', data, {
    'x-year': year
  });
  return response.data;
};

export const updateOwnWorker = async (
  id: string,
  data: Partial<BackendDMOwnWorker>,
  year: string
) => {
  const response = await apiFetch('PATCH', `/v1/sot_stakeholder_own_workers/${id}`, data, {
    'x-year': year
  });
  return response.data;
};

export const deleteOwnWorker = async (id: string) => {
  const response = await apiFetch('DELETE', `/v1/sot_stakeholder_own_workers/${id}`);
  return response.data;
};

/* IROs */
type FetchDMIROsQueryKey = ['iros', string];

export const obtainAllIROs: QueryFunction<
  BackendDMIROResponse,
  FetchDMIROsQueryKey,
  number
> = async ({ queryKey, pageParam = 1 }) => {
  const [, year] = queryKey;
  const { data } = await apiFetch<BackendDMIROResponse>(
    'GET',
    getUrl('/v1/sot_csrd_dm_iro', {
      queryParams: generateQueryParamsFromObject({
        year,
        page: pageParam
      })
    })
  );

  return data;
};

export const createIRO = async (data: BackendDMIRO, year: string) => {
  const response = await apiFetch('POST', '/v1/sot_csrd_dm_iro', data, {
    'x-year': year
  });
  return response.data;
};

export const updateIRO = async (id: string, data: Partial<BackendDMIRO>, year: string) => {
  const response = await apiFetch('PATCH', `/v1/sot_csrd_dm_iro/${id}`, data, {
    'x-year': year
  });
  return response.data;
};

export const deleteIRO = async (id: string, year: string): Promise<{ success: boolean }> => {
  const response = await apiFetch('DELETE', `/v1/sot_csrd_dm_iro/${id}`, {
    'x-year': year
  });
  return response.data;
};

/* CSRD Datapoints */
export type DatapointUnit = {
  id: string;
  name: string;
  type: string;
};

export type DatapointItemOutputData = {
  id: string;
  base_data_id: string;
  start_date: string;
  end_date: string;
  value: string | boolean | File | undefined;
  description: string;
  data_type: string;
  file_urls?: string[];
  name_dcycle: string;
  name_es: string;
  name_en: string;
  name_pt: string;
  total: number;
  page: number;
  size: number;
  unit: DatapointUnit;
};

export type DatapointOutputData = {
  items: DatapointItemOutputData[];
  total: number;
  page: number;
  size: number;
  base_data_id: string;
};

export type fetchDatapointOutputDataProps = {
  baseDataId: string;
  year: string;
};

export const fetchDatapointSourceOutputData = async (
  props: fetchDatapointOutputDataProps
): Promise<DatapointOutputData> => {
  const { baseDataId, year } = props;
  const baseDataURL = `/sot_organization_base_data`;

  const { data: baseData } = await apiFetch<BaseData[]>(
    'GET',
    getUrl(baseDataURL, {
      queryParams: generateQueryParamsFromObject({
        output_data_id: baseDataId
      })
    })
  );
  if (baseData.length === 0) {
    throw new Error('Base data not found');
  }
  const sourceDataURL = `/v1/sot_get_organization_source_data`;
  const response = await apiFetch<DatapointOutputData>(
    'GET',
    getUrl(sourceDataURL, {
      queryParams: generateQueryParamsFromObject({
        base_data_id: baseData[0].id,
        year
      })
    })
  );
  return { ...response.data, base_data_id: baseData[0].id };
};

export type CreateDatapoinSourceDatatBody = {
  base_data_id: string;
  start_date: string;
  end_date?: string;
  value: string | boolean | File | undefined;
  description?: string;
  completed: boolean;
  data_type: string;
  file_urls?: string[];
  unit_id: string;
  newFiles: File[];
};

export const createDatapointSourceData = async (body: CreateDatapoinSourceDatatBody) => {
  let newUrls: string[] = [];
  if (body.newFiles.length > 0) {
    const presignedURLs = await Promise.all(
      body.newFiles.map(async (file) => {
        return requestEvidencePresignedURL({
          baseDataId: body.base_data_id,
          fileName: file.name,
          year: new Date(body.start_date).getFullYear()
        });
      })
    );

    await Promise.all(
      presignedURLs.map((presignedURL, index) => {
        return uploadFilePresignedUrl(body.newFiles[index], presignedURL.upload_url);
      })
    );

    newUrls = presignedURLs.map((url) => url.destination_file_url);
  }

  const response = await apiFetch('POST', `/v1/sot_get_organization_source_data`, {
    ...body,
    file_urls: newUrls
  });
  return response.data;
};

export type UpdateDatapoinSourceDatatBody = {
  base_data_id: string;
  start_date?: string;
  end_date?: string;
  value?: string | boolean | File | undefined;
  description?: string;
  completed?: boolean;
  data_type?: string;
  file_urls?: string[];
  unit_id?: string;
  newFiles?: File[];
};

export const updateDatapointSourceData = async (
  id: string,
  body: UpdateDatapoinSourceDatatBody
) => {
  let newUrls: string[] = [];
  if (body.newFiles && body.newFiles.length > 0 && body.start_date) {
    const presignedURLs = await Promise.all(
      body.newFiles.map(async (file) => {
        return requestEvidencePresignedURL({
          baseDataId: body.base_data_id,
          fileName: file.name,
          year: new Date(body.start_date!).getFullYear()
        });
      })
    );

    await Promise.all(
      presignedURLs.map((presignedURL, index) => {
        return uploadFilePresignedUrl(body.newFiles![index], presignedURL.upload_url);
      })
    );

    newUrls = presignedURLs.map((url) => url.destination_file_url);
  }

  const response = await apiFetch('PATCH', `/v1/sot_get_organization_source_data/${id}`, {
    ...body,
    file_urls: [...(body.file_urls || []), ...newUrls]
  });
  return response.data;
};

export const assignDatapointToUser = async (id: string, taskId: string) => {
  const response = await apiFetch('PATCH', `/sot_organization_csrd_output_data/${id}`, {
    task_id: taskId
  });
  return response.data;
};

/* Evidences */

export type EvidencePresignedURLRequest = {
  baseDataId: string;
  fileName: string;
  year: number;
};

export type EvidencePresignedURLResponse = {
  base_data_id: string;
  file_name: string;
  year: number;
  upload_url: string;
  destination_file_url: string;
  file_id: string;
  destination_file_key: string;
  message: string;
};

export const requestEvidencePresignedURL = async ({
  baseDataId,
  fileName,
  year
}: EvidencePresignedURLRequest): Promise<EvidencePresignedURLResponse> => {
  const response = await apiFetch(
    'POST',
    '/v1/sot_get_organization_source_data/evidence_presigned_url',
    {
      base_data_id: baseDataId,
      file_name: fileName,
      year
    }
  );
  return response.data;
};

/* Base Data */
export type BaseData = {
  id: string;
  name_dcycle: string;
  source: string;
  sector: string;
  name_es: string;
  name_en: string;
  name_pt: string;
};
export type fetchBaseDataProps = {
  outputDataId: string;
  sector: string;
  year: string;
};

export const fetchBaseData = async (props: fetchBaseDataProps): Promise<BaseData[]> => {
  try {
    const { outputDataId, sector, year } = props;
    const url = `/sot_organization_base_data?output_data_id=${outputDataId}&sector=${sector}&year=${year}`;
    const response = await apiFetch('GET', url);
    if (!response || !response.data) {
      throw new Error('Invalid response structure');
    }
    return response.data as BaseData[];
  } catch (error) {
    console.error('Error fetching base data:', error);
    throw new Error('Failed to fetch base data. Please try again later.');
  }
};
