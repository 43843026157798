import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import { useSurveyResultsQuery } from './hooks/useSurveyResultsQuery';
import { Column } from 'types/table';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';
import TotalLegend from 'components/ui/totalLegend/TotalLegend';
import Label from 'components/ui/label/Label';
import Icon from 'components/ui/icon/Icon';
import Tooltip from 'components/ui/tooltip/Tooltip';
import { FilterDatesContext } from 'context/filterDatesContext';

export const DMSurveyResults = () => {
  const {
    t: tG,
    i18n: { resolvedLanguage }
  } = useTranslation();
  const { t } = useTranslation('', {
    keyPrefix: 'doubleMateriality.surveyResults.table'
  });

  const { selectedYear } = useContext(FilterDatesContext);
  const {
    data: surveyResultsData,
    isLoading,
    fetchNextPage
  } = useSurveyResultsQuery(resolvedLanguage, selectedYear);

  const [searchValue, setSearchValue] = useState('');

  const filteredSurveyResults = useMemo(
    () =>
      surveyResultsData?.surveyResults.filter((result) => {
        const searchMatch =
          result.topic.toLowerCase().includes(searchValue.toLowerCase()) ||
          result.subtopic.toLowerCase().includes(searchValue.toLowerCase()) ||
          tG(`controlPanel.metrics.categories.${result.esgCategory}`)
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        return searchMatch;
      }) ?? [],
    [surveyResultsData?.surveyResults, searchValue]
  );

  const columns: Column[] = [
    {
      title: t('category'),
      dataIndex: 'esgCategory',
      key: 'esgCategory',
      width: '23%',
      render: (esgCategory) => {
        return <>{tG(`controlPanel.metrics.categories.${esgCategory}`)}</>;
      }
    },
    {
      title: t('topic'),
      dataIndex: 'topic',
      key: 'topic',
      width: '26%'
    },
    {
      title: t('subtopic'),
      dataIndex: 'subtopic',
      key: 'subtopic',
      width: '23%'
    },
    {
      title: t('financialScore'),
      dataIndex: 'financialScore',
      key: 'financialScore',
      width: '14%',
      numberFormatter: false,
      render: (_, row) => {
        return (
          <Label
            lookAndFeel={row?.financialScore && +row.financialScore >= 70 ? 'error' : 'success'}
            size='medium'
            style={{ fontSize: '12px' }}>
            {row.financialScore}
          </Label>
        );
      }
    },
    {
      title: (
        <div className='flex items-center' style={{ marginTop: '-5px' }}>
          <span>{t('impactScore')}</span>
          <Tooltip text={t('impactScoreTooltip')} style={{ padding: '4px', marginTop: '2px' }} />
        </div>
      ),
      dataIndex: 'impactScore',
      key: 'impactScore',
      width: '14%',
      numberFormatter: false,
      render: (_, row) => {
        return (
          <Label
            lookAndFeel={row?.impactScore && +row.impactScore >= 70 ? 'error' : 'success'}
            size='medium'
            style={{ fontSize: '12px' }}>
            {row.impactScore}
          </Label>
        );
      }
    }
  ];

  return (
    <InfiniteList
      fetchData={fetchNextPage}
      data={filteredSurveyResults}
      columns={columns}
      loading={isLoading}
      firstLoading={isLoading}
      total={surveyResultsData?.total ?? 0}
      header={
        <div className='flex justify-between items-center w-full gap-4'>
          <div className='flex items-center gap-3'>
            <InputWrapper icon={'/images/icons/search.svg'}>
              <InputText
                placeholder={t('searchPlaceholder')}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </InputWrapper>
            <Label lookAndFeel='secondary' className='rounded-4'>
              <div className='flex items-center gap-1'>
                <Icon icon='multi_star' color='pending' />
                <span className='font-12'>{t('description')}</span>
              </div>
            </Label>
          </div>
          <TotalLegend
            total={filteredSurveyResults.length}
            loading={isLoading}
            i18key={'doubleMateriality.topics'}
          />
        </div>
      }
    />
  );
};
