// export const environment = {
//   amplify: {
//     Auth: {
//       region: 'eu-west-1',
//       userPoolId: 'eu-west-1_a44gff2tY',
//       userPoolWebClientId: '1qg61qrak1rv0kuagk12jv719b',
//       oauth: {
//         domain: 'dcycle-backend-auth-juanma-dev-dev.auth.eu-west-1.amazoncognito.com',
//         scope: ['email', 'openid'],
//         redirectSignIn: 'https://growth-app.dcycle.io/callback',
//         redirectSignOut: 'https://growth-app.dcycle.io/',
//         responseType: 'code'
//       }
//     }
//   },
//   backendUrl: 'https://ond7f7vowg.execute-api.eu-west-1.amazonaws.com/dev',
//   ldClientSideID: '63e0c1ec62e30213915d1fd6',
//   typeformEventUrl: {
//     en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
//     pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
//     es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
//   },
//   clientUrl: 'http://localhost:3000/'
// };

// export const environment = {
//   amplify: {
//     Auth: {
//       region: 'eu-west-1',
//       userPoolId: 'eu-west-1_whJvVScVh',
//       userPoolWebClientId: '53ti9r0i1ak344gde40uc7vm4b',
//       oauth: {
//         domain: 'dcycle-backend-auth-migueldev-dev.auth.eu-west-1.amazoncognito.com',
//         scope: ['email', 'openid'],
//         redirectSignIn: 'https://growth-app.dcycle.io/callback',
//         redirectSignOut: 'https://growth-app.dcycle.io/',
//         responseType: 'code'
//       }
//     }
//   },
//   backendUrl: 'https://api.migueldev.dcycle.io',
//   ldClientSideID: '63e0c1ec62e30213915d1fd6',
//   typeformEventUrl: {
//     en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
//     pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
//     es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
//   },
//   clientUrl: 'http://localhost:3000/'
// };

const environmentMario = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_8hIfPErVo',
      userPoolWebClientId: 'br27g5suqcgg2ir628ad4g2l3',
      oauth: {
        domain: 'dcycle-backend-auth-mariogarcia-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://growth-app.dcycle.io/callback',
        redirectSignOut: 'https://growth-app.dcycle.io/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://api.mariogarcia.dcycle.io',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};

export const environmentAnto = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_nHoPbCUxB',
      userPoolWebClientId: '2cj3tu99pbbtepobocq0rgjdp3',
      oauth: {
        domain: 'dcycle-backend-auth-antonellamasini-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://growth-app.dcycle.io/callback',
        redirectSignOut: 'https://growth-app.dcycle.io/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://api.antonellamasini.dcycle.io',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};

export const environment = environmentMario;

export const environmentMigue = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_whJvVScVh',
      userPoolWebClientId: '53ti9r0i1ak344gde40uc7vm4b',
      oauth: {
        domain: 'dcycle-backend-auth-migueldev-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/callback',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://api.migueldev.dcycle.io',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};
