import { useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { setNotification } from 'actions/notification';
import { adaptIROFrontToBack, adaptIROsBackToFront } from 'adapters/iros';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createIRO, deleteIRO, obtainAllIROs, updateIRO } from 'services/api/sotOrganizationData';

export const useObtainIROs = ({ year }: { year: string }) => {
  const { data, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['iros', year],
    queryFn: obtainAllIROs,
    initialPageParam: 1,
    staleTime: 5 * 60 * 1000,
    select: adaptIROsBackToFront,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    }
  });

  return { data, fetchNextPage, isLoading };
};

export const useCreateOrUpdateIro = ({ year }: { year: string }) => {
  const { t } = useTranslation('', { keyPrefix: 'notification' });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isPending } = useMutation({
    mutationFn: (iro: DMIRO) => {
      const backendIRO = adaptIROFrontToBack(iro);
      return iro.id ? updateIRO(iro.id, backendIRO, year) : createIRO(backendIRO, year);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['iros'] });
      dispatch(setNotification(t('edit')));
    },
    onError: () => {
      dispatch(setNotification(t('error')));
    }
  });

  return { createOrUpdateIro: mutate, isPending };
};

export const useDeleteIROMutation = ({ year }: { year: string }) => {
  const { t } = useTranslation('', { keyPrefix: 'notification' });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: (iroId: string) => {
      return deleteIRO(iroId, year);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['iros'] });
      dispatch(setNotification(t('delete')));
    },
    onError: () => {
      dispatch(setNotification(t('error')));
    }
  });

  return { deleteIRO: mutate };
};
